import Link from 'next/link';
import Image from 'next/image';
import Container from 'components/container';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

import { PrimaryLink, Sup } from 'shared/styles/components';
import {
  DEVELOPMENT_MAILTO,
  LIBUM_DOCS_NEXUM,
  LIBUM_DOCS_POWERON_PRO,
  LIBUM_KEYWORDS,
  LIBUM_URL,
  POWERON_PRO_INSTALL_URL,
  SALES_MAILTO,
} from 'shared/constants/globals';

import {
  LandingActions,
  LandingAnnouncement,
  LandingBody,
  LandingColumn,
  LandingColumns,
  LandingContainer,
  LandingLogos,
  LandingRow,
} from './landing.styles';

import HOVER_ARROW from 'public/icons/hover_arrow.svg';
import DOWNLOAD_LINK from 'public/icons/eva/download.svg';
import GIFT from 'public/icons/eva/gift.svg';
import PEOPLE_OUTLINE from 'public/icons/eva/people_outline.svg';

import ALTURA_CU_LOGO from 'public/logos/orgs/altura_cu.svg';
import BCU_LOGO from 'public/logos/orgs/bcu.svg';
import BIDWIZER from 'public/logos/orgs/bidwizer.svg';
import JH_LOGO from 'public/logos/orgs/jack_henry.svg';
import PIONEERA_FCU from 'public/logos/orgs/pioneera_fcu.svg';
import MAX_LOGO from 'public/logos/orgs/max_cu.svg';
import TWINSTAR_CU_LOGO from 'public/logos/orgs/twinstar_cu.svg';

interface LandingAction {
  id: string;
  title: string;
  link: string;
  class?: string;
  icon?: React.FC;
}

interface LandingItem {
  id: string;
  title: string;
  subtitle: string;
  button: LandingAction;
  announcement?: LandingAction;
  actions: LandingAction[];
  message: any;
  messageLogos: React.FC;
  orientation: 'ltr' | 'rtl';
}

export default function Landing() {
  const title = 'Libum | Making Credit Union Software a Piece of Cake';
  const description =
    'Libum is a software company driving the modernization of credit unions. Service your members faster with NexumAPI, Glaci, or PowerOn Pro.';

  const products: LandingItem[] = [
    {
      id: 'poweron-pro',
      title: 'For the PowerOns you develop.',
      subtitle: 'Make it easier to write, run, and debug in Visual Studio Code.',
      button: {
        id: 'poweron-pro-install',
        title: 'Install Now',
        link: POWERON_PRO_INSTALL_URL,
        class: 'install',
        icon: () => (
          <>
            <DOWNLOAD_LINK />
          </>
        ),
      },
      announcement: {
        id: 'not-a-joke-poweron-pro',
        title: `This Isn't a Joke, PowerOn Pro+ Beta is Ending`,
        link: '/blog/not-a-joke-poweron-pro',
        icon: () => <span className="tag new">New</span>,
      },
      actions: [
        {
          id: 'poweron-pro-what-is',
          title: 'What is PowerOn Pro?',
          link: `/poweron-pro#overview`,
        },
        {
          id: 'poweron-pro-quickstart',
          title: 'Quickstart',
          link: `${LIBUM_DOCS_POWERON_PRO}/quickstart`,
        },
      ],
      message: { __html: 'Loved by hundreds of developers' },
      messageLogos: () => (
        <>
          <ALTURA_CU_LOGO style={{ height: '22px' }} />
          <PIONEERA_FCU style={{ height: '30px' }} />
          <TWINSTAR_CU_LOGO style={{ height: '30px', marginBottom: '2px' }} />
          <JH_LOGO style={{ height: '20px' }} />
        </>
      ),
      orientation: 'ltr',
    },
    {
      id: 'nexum-api',
      title: 'For any SymX call you may need.',
      subtitle: `We translated every SOAP operation to JSON so you don't have to.`,
      button: {
        id: 'nexum-api-request',
        title: 'Request Key',
        link: DEVELOPMENT_MAILTO,
        class: 'request',
        icon: () => (
          <>
            <GIFT />
          </>
        ),
      },
      announcement: {
        id: 'cu-building-the-future',
        title: 'CU Build*ing the Future...',
        link: '/blog/cu-building-the-future',
        icon: () => <span className="tag learn">Learn</span>,
      },
      actions: [
        {
          id: 'nexum-what-is',
          title: 'What is NexumAPI?',
          link: `/nexum#overview`,
        },
        {
          id: 'nexum-api-reference',
          title: 'API Reference',
          link: `${LIBUM_DOCS_NEXUM}/symxchange/api-reference`,
        },
      ],
      message: { __html: 'The software of choice for SymX developers' },
      messageLogos: () => (
        <>
          <BIDWIZER style={{ height: '16px' }} />
          <MAX_LOGO style={{ height: '30px' }} />
          <BCU_LOGO style={{ height: '20px', marginBottom: '2px' }} />
          <TWINSTAR_CU_LOGO style={{ height: '30px', marginBottom: '2px' }} />
        </>
      ),
      orientation: 'ltr',
    },
    {
      id: 'glaci',
      title: 'An overlay for Symitar Quest.',
      subtitle: 'Improve your operational efficiency with an enhanced user experience.',
      button: {
        id: 'glaci-waitlist',
        title: 'Join Waitlist',
        link: SALES_MAILTO,
        class: 'join',
        icon: () => (
          <>
            <PEOPLE_OUTLINE />
          </>
        ),
      },
      announcement: {
        id: 'glaci-big-deal',
        title: 'Why this is a big deal',
        link: '/glaci#inspiration',
        icon: () => <span className="tag discover">Discover</span>,
      },
      actions: [
        {
          id: 'glaci-what-is',
          title: 'What is Glaci?',
          link: `/glaci#overview`,
        },
        {
          id: 'glaci-features',
          title: 'Features',
          link: '/glaci#features',
        },
      ],
      message: { __html: 'Followed by CU<span>s</span> looking to modernize' },
      // @TODO: Waiting on permission for Nebo CU logo (approved, need SVG)
      messageLogos: () => (
        <>
          <MAX_LOGO style={{ height: '30px' }} />
          <PIONEERA_FCU style={{ height: '30px' }} />
        </>
      ),
      orientation: 'ltr',
    },
  ];

  const productImages: Record<string, JSX.Element> = {
    'poweron-pro': (
      <>
        <Image
          className="poweron-pro"
          src="/images/bcb4846d-f24e-4ebc-aa56-9ebc3345d459.png"
          alt="PowerOn Pro in Visual Studio Code with a PowerOn file open"
          height={469}
          width={589}
          priority={true}
          style={{ borderRadius: '8px' }}
        />
      </>
    ),
    'nexum-api': (
      <>
        <Image
          className="symx"
          src="/images/7ea44f4a-8d81-4455-acdd-380b733af5ae.png" // SymX
          alt="Sample XML code for a SymXchange getAccount payload"
          height={333.6}
          width={470.4}
          style={{ borderRadius: '8px' }}
        />
        <Image
          className="naas"
          src="/images/9acdaedd-4b1f-4d66-b72f-7f03949268a5.png" // NaaS
          alt="Sample JSON code for a NexumAPI getAccount payload over SymXchange"
          height={222}
          width={350}
          style={{ borderRadius: '8px' }}
        />
      </>
    ),
    glaci: (
      <>
        <Image
          className="glaci"
          src="/images/6c5da01f-63f7-4b05-b23e-b3c41f13b7eb.png"
          alt="Screenshot of Glaci overlay for Symitar Quest with a member profile open"
          height={448.76}
          width={260.41}
          style={{ borderRadius: '8px' }}
        />
        <Image
          className="glaci-toggle"
          src="/images/446aa35a-5893-49df-9578-3276d4acdcaa.png"
          alt="Screenshot of Glaci toggle button in Symitar Quest"
          height={40}
          width={40}
          style={{ borderRadius: '33%' }}
        />
      </>
    ),
  };

  return (
    <Container
      title={title}
      description={description}
      keywords={LIBUM_KEYWORDS}
      url={`${LIBUM_URL}/`}
      socialCard="libum"
    >
      <div style={{ width: '100%' }}>
        <Swiper
          modules={[Autoplay, Keyboard, Pagination]}
          autoplay={{ delay: 7000, disableOnInteraction: false }}
          keyboard={true}
          loop={true}
          pagination={{ clickable: true }}
        >
          {...products.map((p) => (
            <SwiperSlide key={p.id}>
              <LandingContainer>
                <LandingBody>
                  <LandingColumns className={p.orientation}>
                    <LandingColumn className="left">
                      <div className="image-container">
                        {p.id && productImages[p.id]}
                        <div className="dot-mask"></div>
                      </div>
                    </LandingColumn>
                    <LandingColumn className={`right ${p.id}`}>
                      <LandingRow>
                        <h1>{p.title}</h1>
                        <p>{p.subtitle}</p>
                      </LandingRow>
                      <LandingRow>
                        {p.button && (
                          <>
                            <PrimaryLink href={p.button.link} className={`landing-button ${p.button.class}`}>
                              {p.button.title}
                              {p.button.icon && p.button.icon({})}
                            </PrimaryLink>
                          </>
                        )}
                      </LandingRow>
                      <LandingRow>
                        {p.announcement && (
                          <>
                            <LandingAnnouncement>
                              {p.announcement.icon && p.announcement.icon({})}
                              <Link href={p.announcement.link}>
                                {p.announcement.title.split('').map((t, idx) => {
                                  if (t === '+') {
                                    return <Sup key={idx}>{t}</Sup>;
                                  }
                                  return t;
                                })}
                                <HOVER_ARROW />
                              </Link>
                            </LandingAnnouncement>
                          </>
                        )}
                        <LandingActions>
                          {p.actions.map((action) => (
                            <Link
                              key={action.id}
                              href={action.link}
                              target={action.link.includes('http') ? '_blank' : '_self'}
                            >
                              {action.title}
                            </Link>
                          ))}
                        </LandingActions>
                        <LandingLogos>
                          <p dangerouslySetInnerHTML={p.message}></p>
                          <div className="icons">{p.messageLogos({})}</div>
                        </LandingLogos>
                      </LandingRow>
                    </LandingColumn>
                  </LandingColumns>
                </LandingBody>
              </LandingContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  );
}
